import Footer from "./components/footer"
import Header from "./components/header"
import GetInTouch from "./pages/get_in_touch";
import Home from "./pages/home"
import Projects from "./pages/projects";
import PageNotFound from "./pages/page_not_found";
import Blogs from "./pages/blog";
import { useEffect, useState } from "react";
import BlogPost from "./pages/blog_post";

function Main() {
    const [pathName, setPathName] = useState(window.location.pathname || "/");

    useEffect(() => {
        const handlePopState = () => setPathName(window.location.pathname);
        window.addEventListener('popstate', handlePopState);

        return () => window.removeEventListener('popstate', handlePopState);
    }, []);


    const isBlogPost = pathName.startsWith('/blog/') && pathName.split('/').length === 3;
    const blogId = isBlogPost ? pathName.split('/')[2] : null;

    const isPathMatched = () => {
        const paths = ["/", "/get-in-touch", "/projects", "/blog"];
        // if (isNavigate && (paths.includes(pathName) || isBlogPost)) return true
        // else return false;
        return (paths.includes(pathName) || isBlogPost);
    };

    const navigate = (path) => {
        window.history.pushState({}, '', path);
        setPathName(path);
    };
    
    return <>
        <Header />
        {isPathMatched() ? (
            <>
                {pathName === "/" && <Home />}
                {pathName === "/get-in-touch" && <GetInTouch />}
                {pathName === "/projects" && <Projects />}
                {pathName === "/blog" && <Blogs />}
                {isBlogPost && <BlogPost id={blogId} navigate={navigate} />}
            </>
        ) : (
            <PageNotFound />
        )}
        <Footer />
    </>
}

export default Main
