import React from 'react';
import Style from "./style.module.scss";
import { blogs } from '../../data';

function Blogs() {
    return (
        <section className={`container min-height ${Style.blogs}`}>
            <div className={Style.title}>
                <h2> Blogs </h2>
            </div>
            <div className={Style.cards}>
                {blogs && blogs.length > 0 && blogs.slice(0, 10).map((item, index) => (
                    <div className={Style.card} key={index}>
                        <a href={void (0)} onClick={() => { window.location.href = `/blog/${item.id}` }}>
                            <div className={Style.image}>
                                <img src={item.img} alt={item.title} title={item.title} height={'100%'} width={'100%'} />
                            </div>
                            <p style={{ display: "block", maxWidth: "420px", marginTop: "10px", lineHeight: '20px' }}>
                                {item.title}
                            </p>
                        </a>
                    </div>
                ))}
            </div>

        </section>
    )
};

export default Blogs;