import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CheckCircle, ChevronLeft, ChevronRight, Quote, Star } from '../../assets/images';
import { clientsTestimonials, faqs, membershipPlans, projects, services, whyChooseUs } from '../../data';
import Accordion from './components/accordion';
import Style from "./style.module.scss";

function Home() {

  let servicesBreakPoints = {
    320: {
      slidesPerView: 1.1,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 2.2,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
  }
  let clientsTestimonialsBreakPoints = {
    0: {
      slidesPerView: 1.1,
      spaceBetween: 16,
    },
    576: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
  }

  function viewAllProjects() {
    window.location.href = "/projects";
  }

  function getInTouch(plan) {
    window.location.href = `/get-in-touch?plan=${plan}`;
  }

  return <>
    <section id='plans' className={`container ${Style.plans}`}>
      <div className={Style.title}>
        <h1>Build Your <span style={{ color: '#2A4D05' }}>Dream</span> Online Store <span style={{ color: '#2A4D05' }}>Today</span></h1>
        <p>You can easily start selling online! Make a beautiful website and use Shopify to easily manage your store. Fast, dependable, and completely configurable, this solution is ideal for companies of all sizes. Get started today!
        </p>
      </div>

      <div className={Style.cards}>
        {membershipPlans && membershipPlans.length > 0 && membershipPlans.map((item, index) => (
          <div className={`${Style.card} ${index === 1 ? Style.active : ''}`} key={index}>
            <div>
              <div className={Style.planTitle}>
                <div>
                  <h3 className='tx-title'>{item.title}</h3>
                  <p className='text-black-500 tx-sub-title'>{item.description}</p>
                </div>
                {item.tag && <span className={Style.tag}>{item.tag}</span>}
              </div>
              <div className={Style.price}>
                <h2>{item.price}</h2>
                <span>{item.pricing_type}</span>
                <span>{item.disccount}</span>
              </div>
              <div className={Style.divider}></div>

              {item.features && item.features.length > 0 &&
                <div className={Style.features}>
                  <p className='tx-sub-title fw-medium'>This Plane Includes: </p>
                  <ul>
                    {item.features.map((feature, featureIndex) => (
                      <li key={featureIndex}>
                        <img src={CheckCircle} alt='sellfromweb' />
                        <p>{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </div>
            <div className={Style.actionBtn}>
              <button onClick={() => getInTouch(item.plan)}>Get started</button>
            </div>
          </div>
        ))}
      </div>
    </section>

    <section id="services" className={`container ${Style.services}`}>
      <div className={Style.title}>
        <h2>Shopify development services</h2>
      </div>

      <div className={Style.cards}>
        <Swiper
          breakpoints={servicesBreakPoints}
          modules={[Navigation]}
          navigation={{
            prevEl: '.services-prev',
            nextEl: '.services-next',
          }}
        >
          {services && services.length > 0 && services.map((item, index) => (
            <SwiperSlide key={index} className={Style.slide}>
              <div className={Style.card}>
                <img src={item.icon} alt={item.title} />
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={Style.navigation}>
          <a href={void (0)} className='services-prev'><img src={ChevronRight} alt="prev" /></a>
          <a href={void (0)} className='services-next'><img src={ChevronLeft} alt="next" /></a>
        </div>
      </div>
    </section>

    <section id='why-choose-us' className={Style.whyChooseUs}>
      <div className='container'>
        <div className={Style.title}>
          <h2>Why Choose Us?</h2>
        </div>

        <div className={Style.cards}>
          {whyChooseUs && whyChooseUs.length > 0 && whyChooseUs.map((item, index) => (
            <div key={index} className={Style.card}>
              <div className={Style.icon}><img src={item.icon} alt={item.title} /></div>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

    <section id='projects' className={`container ${Style.projects}`}>
      <div className={Style.title}>
        <h2>Our Project</h2>
      </div>
      <div className={Style.cards}>
        {projects && projects.length > 0 && projects.slice(0, 4).map((item, index) => (
          <a href={item.link} target='_blank'>
            <div className={Style.card} key={index}>
              <img src={item.img} alt={item.name} title={item.name} />
              <p>{item.name}</p>
            </div>
          </a>
        ))}
      </div>

      <div className={Style.actionBtn}>
        <button onClick={viewAllProjects}>View All Projects</button>
      </div>
    </section>

    <section id="clients-testimonials" className={`container ${Style.clientsTestimonials}`}>
      <div className={Style.title}>
        <h2>What Our Clients Say</h2>
      </div>

      <div className={Style.cards}>
        <Swiper
          breakpoints={clientsTestimonialsBreakPoints}
          loop={true}
          modules={[Navigation]}
          navigation={{
            prevEl: '.testimonials-prev',
            nextEl: '.testimonials-next',
          }}
        >
          {clientsTestimonials && clientsTestimonials.length > 0 && clientsTestimonials.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={Style.card}>
                <div className={Style.content}>
                  <div className={Style.stars}>{[...Array(item.rating)].map((_, i) => <img key={i} src={Star} alt="star" />)}</div>
                  <p className={Style.description}>{item.description}</p>
                  <div className={Style.clientInfo}>
                    <div className={Style.client}>
                      <div className={Style.avatar}><img src={item.img} alt={item.name} /></div>
                      <div>
                        <p className='fw-semibold'>{item.name}</p>
                        <span className='tx-caption'>{item.designation}</span>
                      </div>
                    </div>
                    <img src={Quote} alt="quote" title='quote' className={Style.quote} />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={Style.navigation}>
          <a href={void (0)} className='testimonials-prev'><img src={ChevronRight} alt="prev" /></a>
          <a href={void (0)} className='testimonials-next'><img src={ChevronLeft} alt="next" /></a>
        </div>
      </div>
    </section>

    <section id="frequently-asked-questions" className={`container ${Style.frequentlyAskedQuestions}`}>
      <div className={Style.title}>
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className={Style.cards}>
        {faqs && faqs.length > 0 && faqs.map((item, index) => (
          <Accordion key={index} title={item.question} content={item.answer} />
        ))}
      </div>
    </section>
  </>;
}

export default Home;
