import React from "react";
import { Logo, Instagram, Facebook, Twitter, WhatsApp } from '../../assets/images';
import { menu } from '../../data';
import Style from './style.module.scss';

function Footer() {
    return (
        <footer className={Style.footer}>
            <div className="container">
                <div className={Style.footerContent}>
                    <div className={Style.logo}><a href="/"><img src={Logo} alt="sellfromweb" title='sellfromweb' /></a></div>
                    {menu && menu.length > 0 &&
                        <ul className={Style.navLinks}>
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <a href={item.href}>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    }
                {/* Social Media Links */}
                <div className={Style.socialMedia}>
                    <a href="https://www.instagram.com/sellfromweb?igsh=MTgzOTR2ZG14azF3&utm_source=qr" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="sellfromweb" title='sellfromweb'/>
                    </a>
                    <a href="https://www.facebook.com/share/1DZ3T6bCMe/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                        <img src={Facebook} alt="sellfromweb" title='sellfromweb' />
                    </a>
                    <a href="https://x.com/sell_from_web" target="_blank" rel="noopener noreferrer">
                        <img src={Twitter} alt="sellfromweb" title='sellfromweb' />
                    </a>
                    <a href="https://wa.me/9054908189" target="_blank" rel="noopener noreferrer">
                        <img src={WhatsApp} alt="sellfromweb" title='sellfromweb' />
                    </a>
                </div>
                </div>
                <div className={Style.copyRight}>
                    <p>© 2024 Sellfromweb. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
};

export default Footer