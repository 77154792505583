import React from 'react';
import Style from "./style.module.scss";
import { errorImg } from '../../assets/images';

function PageNotFound() {
    return (
        <section className={`container min-height ${Style.errorPage}`}>
            <img src={errorImg} alt='404' />
            <div className={Style.title}>
                <h2>Something went wrong</h2>
                <p>Sorry, We can't find the page you're looking for.</p>
            </div>
            <button onClick={() => { window.location.href = "/" }}>Go Back </button>
        </section>
    )
};

export default PageNotFound;