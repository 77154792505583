import React, { useEffect } from 'react';
import Style from "./style.module.scss";
import { blogs } from '../../data';
import BlogPost1 from './post/post_1';
import BlogPost2 from './post/post_2';
import BlogPost3 from './post/post_3';
import BlogPost4 from './post/post_4';
import BlogPost5 from './post/post_5';
import BlogPost6 from './post/post_6';
import BlogPost7 from './post/post_7';
import BlogPost8 from './post/post_8';
import BlogPost9 from './post/post_9';


function BlogPost({ id, navigate }) {
    const post = blogs.find((post) => post.id === id)

    useEffect(() => {
        if (!post) setTimeout(() => { navigate('/blog') }, 50);
    }, [post, navigate]);

    return (
        <section className={`container min-height ${Style.blogs}`}>
            {id == '1' ? <BlogPost1 post={post} nextPost={post} prevPost={post} /> : <></>}
            {id == '2' ? <BlogPost2 post={post} /> : <></>}
            {id == '3' ? <BlogPost3 post={post} /> : <></>}
            {id == '4' ? <BlogPost4 post={post} /> : <></>}
            {id == '5' ? <BlogPost5 post={post} /> : <></>}
            {id == '6' ? <BlogPost6 post={post} /> : <></>}
            {id == '7' ? <BlogPost7 post={post} /> : <></>}
            {id == '8' ? <BlogPost8 post={post} /> : <></>}
            {id == '9' ? <BlogPost9 post={post} /> : <></>}
        </section>
    )
};

export default BlogPost;